import {useCallback, useMemo} from 'preact/hooks';

import {CORE_AUTH_DOMAIN, PAY_AUTH_DOMAIN} from '~/constants/authorize';
import {useI18nContext} from '~/foundation/I18n/hooks';
import {useRootProvider} from '~/foundation/RootProvider/hooks';
import type {BuildAuthorizeUrlParams} from '~/types/authorizeUrlParams';
import type {UxModeType} from '~/types/uxMode';
import {buildUrlSearchParams} from '~/utils/buildUrlSearchParams';

export function useAuthorizeUrl({
  analyticsContext,
  avoidPayAltDomain = false,
  avoidSdkSession = false,
  disableSignUp = false,
  flow = 'default',
  flowVersion = 'unspecified',
  error,
  prompt = 'login',
  responseMode,
  ...props
}: BuildAuthorizeUrlParams) {
  const {locale} = useI18nContext();
  const {instanceId} = useRootProvider();

  const buildAuthorizeUrl = useCallback(
    (additionalProps?: Partial<BuildAuthorizeUrlParams>) => {
      const adjustedResponseMode = adjustResponseMode(
        responseMode,
        props.uxMode,
      );

      const payload = {
        analyticsContext,
        analyticsTraceId: instanceId,
        avoidPayAltDomain,
        avoidSdkSession,
        disableSignUp,
        error,
        flow,
        flowVersion,
        locale,
        prompt,
        ...(adjustedResponseMode && {responseMode: adjustedResponseMode}),
        ...props,
        ...additionalProps,
      } as BuildAuthorizeUrlParams;

      if (!payload.proxy && payload?.clientId === undefined) {
        return '';
      }

      const params = buildUrlSearchParams(payload);

      if (payload.proxy) {
        return `${CORE_AUTH_DOMAIN}/services/login_with_shop/authorize?${params}`;
      }

      if (shouldUseNewUI(payload)) {
        return `${PAY_AUTH_DOMAIN}/oauth/authorize?${params}`;
      }

      /**
       * When `avoidPayAltDomain` is false, we need to route requests through the shop.app/pay/sdk-session endpoint.
       * The session endpoint will allow us to check for the presence of a Pay user session  and hoist it to the
       * "alt" pay.shopify.com/pay/sdk-authorize endpoint.
       */
      const path = payload.avoidPayAltDomain
        ? '/pay/sdk-authorize'
        : '/pay/sdk-session';

      return `${PAY_AUTH_DOMAIN}${path}?${params}`;
    },
    [
      analyticsContext,
      avoidPayAltDomain,
      avoidSdkSession,
      disableSignUp,
      error,
      flow,
      flowVersion,
      instanceId,
      locale,
      prompt,
      props,
      responseMode,
    ],
  );

  const authorizeUrl = useMemo(() => buildAuthorizeUrl(), [buildAuthorizeUrl]);

  return {
    authorizeUrl,
    buildAuthorizeUrl,
  };
}

// For new customer accounts, if uxMode is windoid or redirect,
// deliver the new login UI, except for the PreAuthPrompt
function shouldUseNewUI(payload: BuildAuthorizeUrlParams) {
  return (
    payload.analyticsContext === 'loginWithShopSelfServe' &&
    payload.uxMode !== 'iframe' &&
    payload.uxRole !== 'prompt'
  );
}

/**
 * Adjusts the response mode based on the provided UX mode.
 *
 * @param responseMode - The initial response mode, which can be a string or undefined.
 * @param uxMode - The UX mode which determines how the response mode should be adjusted.
 *                 It can be 'iframe', 'redirect', or 'windoid'.
 * @returns The adjusted response mode based on the UX mode. If the UX mode is 'iframe',
 *          it returns the original response mode. If the UX mode is 'redirect', it returns 'query'.
 *          If the UX mode is 'windoid', it returns 'web_message'.
 */
function adjustResponseMode(
  responseMode: string | undefined,
  uxMode?: UxModeType,
) {
  if (uxMode === 'redirect') {
    // In a redirect flow, we need to use query response mode to signal the BE to
    // redirect the user back to the redirect URI with the authorization code
    // rather than emitting a postmessage to the parent window.
    return 'query';
  }

  if (uxMode === 'windoid') {
    return 'web_message';
  }

  return responseMode;
}
